import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import "./contact.css"

const Contact = () => (
    <Layout
        pageClass="contact"
        offsetHeader={true}
    >
        <Seo title="Contact" />
        <h1 className="contact-page-title font-lato">Contact</h1>
        <section className="contact-section">
            <div className="contact-container container-max">
                <div className="contact-logo">
                    <img src="/img/porcelain_mark.svg" alt="Fabrica Aster" className="contact-logo-image" />
                </div>
                <div className="contact-items">                    
                    <a href="mailto:info@fabrica-aster.com" className="contact-item">
                        <p className="font-lato">info@fabrica-aster.com</p>
                    </a>
                    <a href="tel:820.500.1024" className="contact-item">
                        <p className="font-lato">820.500.1024</p>
                    </a>
                </div>
                <div className="contact-socials">                    
                    <a href="https://www.pinterest.com/fabricaaster/" target="_blank" rel="noopener noreferrer" className="contact-social">
                        <img src="/img/pinterest.svg" alt="Fabrica Aster Pinterest" className="contact-social-image" />
                    </a>
                    <a href="https://www.instagram.com/fabrica_aster/" target="_blank" rel="noopener noreferrer" className="contact-social">
                        <img src="/img/instagram.svg" alt="Fabrica Aster Instagram" className="contact-social-image" />
                    </a>
                </div>
                <p className="contact-social-handle font-lato"></p>
            </div>
        </section>
    </Layout>
)

export default Contact
